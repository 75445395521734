
export default {
  path: '/',
  meta: {
    title: '维构科技'
  },
  component: () => import('@/pages/index/index.vue'),
  children: [
    {
      path: '',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/pages/home/index.vue')
    },
    {
      path: '/case',
      name: 'case',
      component: () => import('@/pages/caseShow/index.vue')
    },
    {
      path: '/business',
      name: 'business',
      component: () => import('@/pages/coreBusiness/index.vue')
    },
    {
      path: '/dynamic',
      name: 'dynamic',
      component: () => import('@/pages/ConsultingDynamic/index.vue')
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('@/pages/aboutUs/index.vue')
    },
    {
      path: '/details/:id',
      name: 'details',
      component: () => import('@/pages/caseDetails/index.vue')
    }
  ]
}
