/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return { timeStr, formatObj }
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()
  const diff = (now - d) / 1000
  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

//将秒数转换为天时分秒格式
export function setSeconds(value) {
  var result = ''
  var sencond = parseInt(value)
  //3600秒等于60分钟等于1小时
  if (sencond > 3600 * 24) { //表示大于一天
    let day = sencond / (3600 * 24);
    sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
    let hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
    result = parseInt(day) + '天' + parseInt(hour) + '时';
  } else if (sencond > 3600) { //表示大于一个小时
    let hour = parseInt((sencond / 3600));
    let minute = parseInt((sencond % 3600 / 60)); //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
    let s = parseInt((minute / 60))
    if (hour < 10) {
      hour = '0' + hour
    }
    if (minute < 10) {
      minute = '0' + minute
    }
    if (s < 10) {
      s = '0' + s
    }
    result = hour + ':' + minute + ':' + s;
  } else if (sencond > 60) { //表示大于一分钟
    let minute = sencond / 60;
    let sec = sencond % 60; //求分钟数整除外剩余的秒数
    result = parseInt(minute) + ':' + parseInt(sec);
  } else { //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
    result = '' + parseInt(sencond);
  }
  return result
}

//生成验证码
export function getMoreCode() {
  var moreCode = new Array(
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
    'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
    'u', 'v', 'w', 'x', 'y', 'z',
  );
  let codes = '';// 重新初始化验证码
  for (var i = 0; i < 6; i++) {
    // 随机获取一个数组
    var a = parseInt(Math.random() * moreCode.length);
    codes += moreCode[a];
  }
  return codes
}