import request from '@/utils/request'

const url = '/api'

/**
 * 获取案例详情
 *  @param {array} params 请求参数
 * id 案例id
 */
export function getCaseById(params) {
    return request({
        url: url + '/woCase/getCaseById',
        method: 'get',
        params: params
    })
}

/**
 * 获取案例分类
 */
export function getCaseType() {
    return request({
        url: url + '/woCase/getCaseType',
        method: 'get',
    })
}

/**
 * 获取案例列表
 *  @param {array} params 请求参数
 * typeId 分类id
 * current 分页参数
 * size 分页参数
 */
export function getCaseTypeById(params) {
    return request({
        url: url + '/woCase/getCaseTypeById',
        method: 'get',
        params: params
    })
}

/**
 * 获取配置
 *  @param {array} params 请求参数
 * configKey 分类名称
 * configClassify 配置分类ID
 */
export function getConfig(params) {
    return request({
        url: url + '/wgConfig/getConfig',
        method: 'get',
        params: params
    })
}

/**
 * 获取轮播图
 *  @param {array} params 请求参数
 * type 轮播图类型
 */
export function getBanner(params) {
    return request({
        url: url + '/banner/getBanner',
        method: 'get',
        params: params
    })
}

/**
 * 获取首页核心业务
 *  @param {array} params 请求参数
 */
 export function getCoreActivity() {
    return request({
        url: url + '/home/getCoreActivity',
        method: 'get',
    })
}

/**
 * 获取首页服务优势
 *  @param {array} params 请求参数
 */
 export function getWgAdvantage() {
    return request({
        url: url + '/home/getWgAdvantage',
        method: 'get',
    })
}

/**
 * 反馈
 *  @param {array} params 请求参数
 */
 export function verIfCode(data) {
    return request({
        url: url + '/verificationCode/verIfCode',
        method: 'post',
        data
    })
}

/**
 * 获取开发流程
 *  @param {array} params 请求参数
 */
 export function getWgDevelopmentProcess() {
    return request({
        url: url + '/home/getWgDevelopmentProcess',
        method: 'get',
    })
}

/**
 * 根据分类Id获取资讯动态
 *  @param {array} params 请求参数
 */
 export function getwgConsultationList(params) {
    return request({
        url: url + '/wgConsultation/getList',
        method: 'get',
		params: params
    })
}

/**
 * 根据分类Id获取资讯动态
 *  @param {array} params 请求参数
 */
 export function getwgConsultationInfoById(params) {
    return request({
        url: url + '/wgConsultation/getInfoById',
        method: 'get',
		params: params
    })
}

/**
 * 获取企业文化列表
 *  @param {array} params 请求参数
 */
 export function getcorporateCultureList() {
    return request({
        url: url + '/corporateCulture/getList',
        method: 'get'
    })
}