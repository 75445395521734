export default {
	index: {
		login: "Welcome To Login",
		lang: "CN",
		header: ["Main", "Case", "Business", "Information", "About"],
		//悬浮按钮
		floating: {
			one: "Consult",
			two: "WeChat",
			three: "QR Code",
			four: "Top ↑",
		},
		consult: 'online consultation',
		//移动端头部
		// teamList: [
		// 	{
		// 		id: 1,
		// 		number: "30",
		// 		unit: "peoples",
		// 		has: true,
		// 		description: "Professional team",
		// 	},
		// 	{
		// 		id: 2,
		// 		number: "8",
		// 		unit: "years",
		// 		has: false,
		// 		description: "Experience in product",
		// 	},
		// 	{
		// 		id: 3,
		// 		number: "500",
		// 		unit: "+",
		// 		has: false,
		// 		description: "Pooperative project",
		// 	},
		// 	{
		// 		id: 4,
		// 		number: "40",
		// 		unit: "0000",
		// 		has: false,
		// 		description: "Proprietary flow",
		// 	},
		// ],
		//pc 专业团队
		teamList: [
			{
				id: 1,
				number: "30",
				unit: "人",
				has: true,
				description: "专业团队",
			},
			{
				id: 2,
				number: "8",
				unit: "年",
				has: false,
				description: "产品经验",
			},
			{
				id: 3,
				number: "500",
				unit: "个",
				has: false,
				description: "合作项目",
			},
			{
				id: 4,
				number: "40",
				unit: "万",
				has: false,
				description: "自营流量",
			},
		],
		team: {
			one: "team",
			// one: "Team",
			two: "Experience",
			// two: "Experience",
			three: "project",
			// three: "Project",
			four: "flow",
			// four: "Flow",
			unit: {
				one: "peoples",
				two: "years",
				three: "",
				four: "ten thousand",
			},
		},
		//核心业务
		corebusiness: {
			one: "Applets development",
			two: "App development",
			three: "H5 development",
			four: "Website construction",
			five: "System integration",
			six: "Platform operation",
		},
		//量身定制
		customized: {
			title: 'High-quality delivery of industry-wide solutions guarantees worry-free iterative maintenance',
			list: [{
				img: require("../../assets/testImg/customized/governmentAffairs.png"),
				name: "government affairs",
			}, {
				img: require("../../assets/testImg/customized/warIndustry.png"),
				name: "war industry",
			}, {
				img: require("../../assets/testImg/customized/e_commerce.png"),
				name: "e_commerce",
			}, {
				img: require("../../assets/testImg/customized/medical.png"),
				name: "medical",
			}, {
				img: require("../../assets/testImg/customized/socialContact.png"),
				name: "social contact",
			}, {
				img: require("../../assets/testImg/customized/trip.png"),
				name: "trip",
			}, {
				img: require("../../assets/testImg/customized/intermediaryAgent.png"),
				name: "intermediary agent",
			}, {
				img: require("../../assets/testImg/customized/financial.png"),
				name: "financial",
			}, {
				img: require("../../assets/testImg/customized/catering.png"),
				name: "catering",
			}, {
				img: require("../../assets/testImg/customized/travel.png"),
				name: "travel",
			}, {
				img: require("../../assets/testImg/customized/education.png"),
				name: "education",
			}, {
				img: require("../../assets/testImg/customized/houseProperty.png"),
				name: "house property",
			}]
		},
		//服务优势
		service: {
			title: {
				one: "Sales Manager for Lean project",
				two: "Improve service system",
				three: "Rich accumulation of technology",
				four: "Fair cooperative quotation",
			},
			desc: {
				one: "Scheme standardized output,Feature mapping+requirement document + Prototype diagram + UI diagram,inspection report,Collaboration tools manage project progress.",
				two: "Pre-sales customer service consulting and answering questions, real-time feedback of project progress, assist product shelves, product operation strategy and promotion channel support.",
				three:
					"More than ten vertical industries of product technology precipitation, technical team from well-known Internet companies",
				four: "Evaluate project quotation layer by layer and report strictly according to function development cycle.",
			},
		},
		//成功案例
		categoryList: [
			"Wisdom government affairs",
			"Wisdom union",
			"Wisdom military",
			"Wisdom park",
			"E-commerce",
			"Medical service",
			"Travel platform",
			"Intermediary services",
			"NFT",
		],
		//成功案例 card
		caseList: [
			{
				img: require("../../assets/testImg/example1.png"),
				title: "Xiamen Branch of Fujian Internet Service Hall",
				type: "Responsive Web",
			},
			{
				img: require("../../assets/testImg/example2.png"),
				title: "Visual supervision and management of Xiamen Housing Bureau...",
				type: "PC端",
			},
			{
				img: require("../../assets/testImg/example3.png"),
				title: "i厦门APP-Political and legal services and engineering construction...",
				type: "APP",
			},
			{
				img: require("../../assets/testImg/example4.png"),
				title: "Digital Silk Road Innovation service platform",
				type: "APP",
			},
			{
				img: require("../../assets/testImg/example5.png"),
				title: "Shanxi Province Emergency mobile communication and emergency department...",
				type: "APP",
			},
			{
				img: require("../../assets/testImg/example3.png"),
				title: "i厦门APP-Political and legal services and engineering construction...",
				type: "APP",
			},
		],
		//开发案例
		developmentCase: "development case",
		//运营案例
		operatingCase: "operating case",
		//点击查看更多
		clickDetail: "Click here for details",
		//更多按钮
		more: "More",

		// 专属方案
		exclusive: {
			title: "Free access to exclusive product solutions",
			content: 'Immediately contact "Dimension interconnection" professional </br> project manager to provide you with product consultation and project quotation!',
			placeholder1: "Phone number or wechat",
			placeholder2: "Verification code",
			btn: "Consult",
		},
		// 业务流程  手机
		phoneProcessList: [
			{
				id: "01",
				title: "Product requirement communication",
				desc: "Communicate effectively with customers to understand product functional requirements",
			},
			{
				id: "02",
				title: "Product planning and design",
				desc: "Organize product requirement documents, prototype design and UI design",
			},
			{
				id: "03",
				title: "Technology customization research and development",
				desc: "Based on product requirements and prototypes, engineers enter the development phase",
			},
			{
				id: "04",
				title: "Considerate agent throughout",
				desc: "Assist in purchasing server and domain name, account and platform registration",
			},
			{
				id: "05",
				title: "Rigorous test acceptance",
				desc: "Found and fixed bugs through multiple internal and third party tests",
			},
			{
				id: "06",
				title: "Regular online maintenance",
				desc: "Formal deployment online, regular review and maintenance to ensure stable operation of products",
			},
		],
		// // 业务流程  pc
		// process: {
		// 	one: {
		// 		number: "01",
		// 		title: "Product requirement communication",
		// 		desc: "Communicate effectively with customers to understand product functional requirements",
		// 	},
		// 	two: {
		// 		number: "02",
		// 		title: "Product planning and design",
		// 		desc: "Organize product requirement documents, prototype design and UI design",
		// 	},
		// 	three: {
		// 		number: "03",
		// 		title: "Technology customization research and development",
		// 		desc: "Based on product requirements and prototypes, engineers enter the development phase",
		// 	},
		// 	four: {
		// 		number: "04",
		// 		title: "Regular online maintenance",
		// 		desc: "Formal deployment online, regular review and maintenance to ensure stable operation of products",
		// 	},
		// 	five: {
		// 		number: "05",
		// 		title: "Rigorous test acceptance",
		// 		desc: "Found and fixed bugs through multiple internal and third party tests",
		// 	},
		// 	six: {
		// 		number: "06",
		// 		title: "Considerate agent throughout",
		// 		desc: "Assist in purchasing server and domain name, account and platform registration",
		// 	},
		// },
		//footer 部分
		process: {
			one: {
				number: "01",
				title: "Product demand communication",
				desc: "Communicate effectively with customers to understand and clarify product functional requirements",
			},
			two: {
				number: "02",
				title: "Product planning and design",
				desc: "Organize product requirement document, prototype design and UI design",
			},
			three: {
				number: "03",
				title: "Technology customization Research and Development",
				desc: "Based on product requirements and prototypes, engineers enter the development phase",
			},
			four: {
				number: "04",
				title: "Online Periodic Maintenance",
				desc: "Formal deployment online, regular review and maintenance, to ensure the stable operation of products",
			},
			five: {
				number: "05",
				title: "Strict test acceptance",
				desc: "Found and fixed bugs through multiple internal and third-party tests",
			},
			six: {
				number: "06",
				title: "Whole-process caring agent",
				desc: "Assist in purchasing server and domain name, account and platform registration",
			},
		},
		operation: {
			title: 'No matter how good the product is, it also needs operation and promotion',
			content: 'Professional operation team self flow support'
		},
		information: {
			companyInformation: 'company information',
			industryInformation: 'industry information'
		},
		footer: {
			title: {
				one: "Advanced customization solution",
				two: "About weegoo",
				three: "Follow us",
				four: "Weegoo Interconnection",
			},
			footitem: {
				one: "Applets development",
				two: "App development",
				three: "H5 development",
				four: "Website construction",
				five: "System integration",
				six: "Platform operation",
			},
			aboutItem: {
				one: "Business movement ",
				two: "Enterprise profile",
				three: "Enterprise culture",
				four: "Enterprise atmosphere",
			},
			wechatScan: "Wechat scan",
			provide: "Weegoo Internet Content Provider 123456—2",
			synopsis:
				"Founded in 2017 and headquartered in Xiamen, Weegoo is a national high-tech enterprise providing software system development and operation services to the government and enterprises and public institutions. The company is deeply engaged in the field of IT and Internet, focusing on the digital innovation of government and enterprises, and has a group of experienced core teams. Adhering to the principle of service as the guide, technology as the core, operation as the drive, the company meets the digital needs of government and enterprises, and helps government and enterprises build new advantages and new momentum for development. <br/>We have always maintained a good cooperative relationship with each government and enterprise customers, is huawei, Hisense, information group, AIU Cupressoke and other well-known technology enterprises partners, for the military, public security, government affairs, taxation, medical care, e-commerce, rental housing and other industries to provide customers with perfect solutions and implementation of delivery. ",
		},
		//联系方式
		contact: {
			phoneTitle: "Contact Information",
			emailTitle: "Email",
			addressTitle: "Address",
			// phone: "130-7480-0787",
			// email: "Wg13074800787@163.com",
			// address: "Unit 1404, No. 171, Tapu East Road, Siming District, Xiamen",
		},
		//底部tabbar
		tabbarList: [
			{
				id: 0,
				name: "Home",
				status: true,
				icon: require("../../assets/images/tabbar/home-white_@2x.png"),
				actIcon: require("../../assets/images/tabbar/home-mazarine@2x.png"),
			},
			{
				id: 1,
				name: "Case",
				status: false,
				icon: require("../../assets/images/tabbar/case-white@2x.png"),
				actIcon: require("../../assets/images/tabbar/case-mazarine@2x.png"),
			},
			{
				id: 2,
				name: "Business",
				status: false,
				icon: require("../../assets/images/tabbar/business-white@2x@2x.png"),
				actIcon: require("../../assets/images/tabbar/business-mazarine@2x.png"),
			},
			{
				id: 3,
				// name: "Information",
				name: "Info",
				status: false,
				icon: require("../../assets/images/tabbar/information-white@2x.png"),
				actIcon: require("../../assets/images/tabbar/information-mazarine@2x.png"),
			},
			{
				id: 4,
				status: false,
				name: "About",
				icon: require("../../assets/images/tabbar/about-white@2x.png"),
				actIcon: require("../../assets/images/tabbar/about-mazarine@2x.png"),
			},
		],
		consultView: {
			title: 'consult',
			name: 'name',
			contactWay: 'contact way',
			issue: 'Please enter your question',
			submit: 'submit',
		}
	},
};
