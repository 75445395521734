import Cookies from 'js-cookie'

const tokenKey = 'token'
export function setToken(token) {
  return Cookies.set(tokenKey, token)
}
export function getToken() {
  return Cookies.get(tokenKey)
}
export function delToken() {
  return Cookies.remove(tokenKey)
}

