import Vue from "vue";
import Vuex from "vuex";
import getters from './getters.js'
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		//保存目前语言
		language: window.localStorage.getItem("Language"),
		navCurrent: 0,
		isMobile: false,
		consult: { consultDialog: false, phone: null },
		config: null
	},
	getters,
	mutations: {
		//修改语言
		setLang(state, language) {
			state.language = language;
			window.localStorage.setItem("Language", language);
		},
		updateNavCurrent(state, index) {
			state.navCurrent = index
		},
		isMobile(state, isMobile) {
			state.isMobile = isMobile
		},
		setConsult(state, consult) {
			state.consult = {...state.consult, ...consult}
			console.log(state.consult)
		},
		setConfig(state, config) {
			state.config = config
		}
	},
	actions: {
		//
	},
	modules: {},
});
