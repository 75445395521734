import index from './modules/index'
 
const frameIndex=[
	index
]


/**
 * 错误页面
 */
const errorPage = [
    {
        path: '/error/404',
        name: '404',
        meta: {
            title: '404'
        },
        component: () => import('/src/pages/system/error/404/404')
    }
]
export default [
  ...frameIndex,
  ...errorPage,
]