import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // 接口baseURL
  // withCredentials: true, // 跨域请求时发送 Cookie
  timeout: 60000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 发送请求之前
    if (store.getters.token) {
      // 设置Token，请求头部header或请求参数param
      const tokenType = process.env.VUE_APP_TOKEN_TYPE || 'header'
      const tokenName = process.env.VUE_APP_TOKEN_NAME || 'token'
      const tokenValue = getToken()
      if (tokenType === 'header') {
        // 请求头部token
        config.headers[tokenName] = tokenValue
      } else {
        // 请求参数token
        if (config.method === 'get') {
          config.params = { ...config.params, [tokenName]: tokenValue }
        } else {
          config.data = { ...config.data, [tokenName]: tokenValue }
        }
      }
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    return config
  },
  error => {
    // 请求错误
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * 通过接口返回码确定返回状态
   * 还可以通过HTTP状态代码来判断请求状态
   */
  response => {
    // 响应数据
    const res = response.data
    // 返回码200：成功
    if (res.status) {
      if (res.status === 200) {
        return res
      } else {
        responseHandle(res)
        return Promise.reject(new Error(res.msg || 'Server error'))
      }
    } else {
      return res
    }
  },
  error => {
    // 响应错误
    const res = error.response
    responseHandle(res)
    if (process.env.NODE_ENV === 'development') {
      console.log(error.response)
    }
    return Promise.reject(error.response)
  }
)

// 响应处理
function responseHandle(res) {

}

export default service
