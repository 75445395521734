import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import store from './store/index'
import router  from './router/index'
import ElementUI from 'element-ui';
import message from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as common from './utils/common'

import i18n from './i18n/index.js';
Vue.use(ElementUI)
Vue.use(Vuex)
Vue.use(Router)

Vue.config.productionTip = false
Vue.prototype._i18n = i18n
Vue.prototype.$common = common
Vue.prototype.$message = message
Vue.prototype.$store = store
Vue.prototype._api = process.env.VUE_APP_API_URL
// swiper组件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper) /* { default options with global component } */

import 'animate.css';

new Vue({
	store,
	router,
	i18n,
	render: h => h(App),
    // components: { App },
    // template: '<App/>'
}).$mount('#app')
