<template>
  <div id="app">
	<div class='head' :class="{'animate__animated  animate__fadeInDown':showHead}" v-if="showHead">
		<img class="logo-h5" src="@/assets/images/logo-h5.png" alt="" srcset="">
	</div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getConfig } from "@/api/index";
export default {
  name: "App",
  data(){
	  return{
		  flag:true,
		  showHead:false,
	  }
  },
  components: {},
  methods:{
	navScroll() {
		if (!this.flag) return
		this.flag = false
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
		let scrollTopRem = 0
		let appWidth = document.querySelector("#app").clientWidth; // html的宽度
		let htmlWidth = document.documentElement.clientWidth; // html的宽度
		let bodyWidth = document.body.clientWidth; // body宽度
		let w = appWidth || htmlWidth || bodyWidth; // 兼容ie,屏幕宽度
		if (w <= 768 && scrollTop>=60) {
			 this.showHead=true
		}else if(w <= 768 &&scrollTop<60){
			this.showHead=false
		}
		this.flag = true
	}  
  },
  mounted() {
  	window.addEventListener('scroll', this.navScroll)
  },
  updated() {
    let that = this;
    function rem() {
      try {
        let appWidth = document.querySelector("#app").clientWidth; // html的宽度
        let htmlWidth = document.documentElement.clientWidth; // html的宽度
        let bodyWidth = document.body.clientWidth; // body宽度
        let w = appWidth || htmlWidth || bodyWidth; // 兼容ie,屏幕宽度
        if (w <= 768) {
          if (!that.$store.getters.isMobile) {
            that.$store.commit("isMobile", true);
          }
        } else if (w > 768 && w < 1800) {
          if (that.$store.getters.isMobile) {
            that.$store.commit("isMobile", false);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    getConfig().then((res) => {
      let config = res.data;
      that.$store.commit("setConfig", config);
    });
    // 加载或刷新就调用
    rem();
    // 屏幕变化事件
    window.onresize = function () {
      rem(); // 屏幕变化触发rem事件
    };
  },
};
</script>
<style scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #16308f !important;
  color: #fff;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #16308f !important;
  color: #fff;
}
</style>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.head{
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: fixed;
	top: 0;
	z-index: 999;
	background-color: #fff;
	box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
	animation-duration:0.4s;
}
.head  img{
	width: 192px;
	height: 36px;
	margin-left: 16px;
}

@media screen and (min-width:767px) {
	.head {
		visibility: hidden;
	}
}
</style>
