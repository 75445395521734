import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
Vue.use(Router)

const router = new Router({
	mode: 'hash',
	routes
})

router.beforeEach((to, from, next) => {
	/* 
	 *title
	 */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})
export default router
