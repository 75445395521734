import Vue from "vue";
import VueI18n from "vue-i18n";
import LangEn from "./lang/en";
import LangCn from "./lang/zh";
Vue.use(VueI18n);

// 注册i18n实例并引入语言文件
const i18n = new VueI18n({
	//设置当前语言,如果本地缓存有设置,按本地设置,否则默认中文
	locale: window.localStorage.getItem("Language") ? window.localStorage.getItem("Language") : "zh",
	messages: {
		en: LangEn,
		zh: LangCn,
	},
});

export default i18n;
