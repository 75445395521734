export default {
	index: {
		login: "欢迎登录",
		lang: "EN",
		//首页头部,pc状态
		header: ["首页", "案例秀", "核心业务", "资讯动态", "关于我们"],
		//悬浮按钮
		floating: {
			one: "在线咨询",
			two: "添加微信",
			three: "微信二维码",
			four: "回到顶部",
		},
		consult: '在线咨询',
		//移动端头部
		teamList: [
			{
				id: 1,
				number: "30",
				unit: "人",
				has: true,
				description: "专业团队",
			},
			{
				id: 2,
				number: "8",
				unit: "年",
				has: false,
				description: "产品经验",
			},
			{
				id: 3,
				number: "500",
				unit: "个",
				has: false,
				description: "合作项目",
			},
			{
				id: 4,
				number: "40",
				unit: "万",
				has: false,
				description: "自营流量",
			},
		],
		//pc 专业团队
		team: {
			one: "专业团队",
			two: "产品经验",
			three: "合作项目",
			four: "自营流量",
			unit: {
				one: "人",
				two: "年",
				three: "个",
				four: "万",
			},
		},
		//核心业务
		corebusiness: {
			one: "小程序开发",
			two: "APP开发",
			three: "H5开发",
			four: "网站建设",
			five: "系统集成",
			six: "平台运营",
		},
		//量身定制
		customized: {
			title: '全行业解决方案 高质量交付保障 迭代维护更无忧',
			list: [{
				img: require("../../assets/testImg/customized/governmentAffairs.png"),
				name: "政务",
			}, {
				img: require("../../assets/testImg/customized/warIndustry.png"),
				name: "军工",
			}, {
				img: require("../../assets/testImg/customized/e_commerce.png"),
				name: "电商",
			}, {
				img: require("../../assets/testImg/customized/medical.png"),
				name: "医疗",
			}, {
				img: require("../../assets/testImg/customized/socialContact.png"),
				name: "社交",
			}, {
				img: require("../../assets/testImg/customized/trip.png"),
				name: "出行",
			}, {
				img: require("../../assets/testImg/customized/intermediaryAgent.png"),
				name: "中介",
			}, {
				img: require("../../assets/testImg/customized/financial.png"),
				name: "金融",
			}, {
				img: require("../../assets/testImg/customized/catering.png"),
				name: "餐饮",
			}, {
				img: require("../../assets/testImg/customized/travel.png"),
				name: "旅游",
			}, {
				img: require("../../assets/testImg/customized/education.png"),
				name: "教育",
			}, {
				img: require("../../assets/testImg/customized/houseProperty.png"),
				name: "房产",
			}]
		},
		//服务优势
		service: {
			title: {
				one: "精益项目管理",
				two: "完善服务体系",
				three: "丰厚技术积累",
				four: "公正合作报价",
			},
			desc: {
				one: "方案标准化输出,功能导图+需求文档+原型图+UI图,验收报告,协作工具管理项目进展。",
				two: "售前客服咨询答疑,实时反馈项目进展,协助产品上架,产品运营策略及推广渠道支持。",
				three: "十多个垂直行业的产品技术沉淀,技术团队来自知名互联网公司",
				four: "层层评估审核项目报价,严格按功能开发周期报",
			},
		},
		//成功案例  tag
		categoryList: [
			"智慧政务",
			"智慧工会",
			"智慧军工",
			"智慧园区",
			"电子商务",
			"医疗服务",
			"出行平台",
			"中介服务",
			"NFT",
		],
		//成功案例 card
		caseList: [
			{
				img: require("../../assets/testImg/example1.png"),
				title: "福建省网上办事大厅厦门分厅",
				type: "响应式网页",
			},
			{
				img: require("../../assets/testImg/example2.png"),
				title: "厦门市住房局可视化监管管理...",
				type: "PC端",
			},
			{
				img: require("../../assets/testImg/example3.png"),
				title: "i厦门APP-政法服务和工程建...",
				type: "APP",
			},
			{
				img: require("../../assets/testImg/example4.png"),
				title: "数字丝路创新服务平台",
				type: "APP",
			},
			{
				img: require("../../assets/testImg/example5.png"),
				title: "陕西省应急移动通及应急厅工...",
				type: "APP",
			},
			{
				img: require("../../assets/testImg/example3.png"),
				title: "i厦门APP-政法服务和工程建...",
				type: "APP",
			},
		],
		//开发案例
		developmentCase: "开发案例",
		//运营案例
		operatingCase: "运营案例",
		//点击查看更多
		clickDetail: "点击查看更多",
		//更多按钮
		more: "查看更多",
		// 业务流程  手机
		phoneProcessList: [
			{
				id: "01",
				title: "产品需求沟通",
				desc: "与客户高效沟通,理解明确产品功能需求",
			},
			{
				id: "02",
				title: "产品规划设计",
				desc: "整理产品需求文档,进行原型设计及UI设计",
			},
			{
				id: "03",
				title: "技术定制研发",
				desc: "根据产品需求和原型,工程师进入开发阶段",
			},
			{
				id: "04",
				title: "全程贴心代办",
				desc: "协助采购服务器和域名,账号和平台注册等事项",
			},
			{
				id: "05",
				title: "严苛测试验收",
				desc: "通过多次内部测试及第三方测试发现修复BUG",
			},
			{
				id: "06",
				title: "上线定期维护",
				desc: "正式部署上线,定期复查维护,保证产品稳定运行",
			},
		],
		// 业务流程  pc
		process: {
			one: {
				number: "01",
				title: "产品需求沟通",
				desc: "与客户高效沟通，理解明确产品功能需求",
			},
			two: {
				number: "02",
				title: "产品规划设计",
				desc: "整理产品需求文档，进行原型设计及UI设计",
			},
			three: {
				number: "03",
				title: "技术定制研发",
				desc: "根据产品需求和原型，工程师进入开发阶段",
			},
			four: {
				number: "04",
				title: "全程贴心代办",
				desc: "协助采购服务器和域名，账号和平台注册等事项",
			},
			five: {
				number: "05",
				title: "严苛测试验收",
				desc: "通过多次内部测试及第三方测试发现修复BUG",
			},
			six: {
				number: "06",
				title: "上线定期维护",
				desc: "正式部署上线，定期复查维护，保证产品稳定运行",
			},
		},
		operation: {
			title: '再好的产品，也需要运营推广',
			content: '专业运营团队 自营流量扶持'
		},
		information: {
			companyInformation: '公司资讯',
			industryInformation:'行业资讯'
		},
		// 专属方案
		exclusive: {
			title: "免费获取专属产品方案",
			content:"即刻联系「维构互联」专业项目经理为您提供产品咨询和项目报价！",
			placeholder1: "请输入电话或微信",
			placeholder2: "请输入验证码",
			btn: "立即咨询",
		},
		//footer 部分
		footer: {
			title: {
				one: "高级定制方案",
				two: "关于维构互联",
				three: "关注维构互联",
				four: "维构互联",
			},
			footitem: {
				one: "小程序开发",
				two: "APP开发",
				three: "H5开发",
				four: "网站建设",
				five: "系统集成",
				six: "平台运营",
			},
			aboutItem: {
				one: "企业动态 ",
				two: "企业简介",
				three: "企业文化",
				four: "企业氛围",
			},
			wechatScan: "微信扫一扫",
			provide: "ICP备案/许可证号：闽ICP备19000144号",
			synopsis:
				"维构互联创立于2017年，总部位于厦门，是一家向政府及企事业单位提供软件系统研发及运营服务的国家高新技术企业。公司深耕IT互联网领域，聚焦政企数字化革新，拥有一批从业经验丰富的核心团队，秉持以服务为先导，技术为核心，运营为驱动，满足政企数字化需求，助力政企构筑发 展的新优势和新动能。<br /><br />我们与每一位政企客户始终保持着良好的合作关系，是华为、海信、信息集团、美亚柏科等知名科技企业的合作伙伴，为军工、公安、政务、税务、医疗、电商、租赁住房等行业领域客户提供了完善的解决方案并实施交付。",
		},
		//联系方式
		contact: {
			phoneTitle: "联系电话：",
			emailTitle: "电子邮箱：",
			addressTitle: "公司地址：",
			// phone: "130-7480-0787",
			// email: "Wg13074800787@163.com",
			// address: "厦门市思明区塔埔东路171号1404单元之一",
		},
		//底部tabbar
		tabbarList: [
			{
				id: 0,
				name: "首页",
				status: true,
				icon: require("../../assets/images/tabbar/home-white_@2x.png"),
				actIcon: require("../../assets/images/tabbar/home-mazarine@2x.png"),
			},
			{
				id: 1,
				name: "案例秀",
				status: false,
				icon: require("../../assets/images/tabbar/case-white@2x.png"),
				actIcon: require("../../assets/images/tabbar/case-mazarine@2x.png"),
			},
			{
				id: 2,
				name: "核心业务",
				status: false,
				icon: require("../../assets/images/tabbar/business-white@2x@2x.png"),
				actIcon: require("../../assets/images/tabbar/business-mazarine@2x.png"),
			},
			{
				id: 3,
				name: "资讯动态",
				status: false,
				icon: require("../../assets/images/tabbar/information-white@2x.png"),
				actIcon: require("../../assets/images/tabbar/information-mazarine@2x.png"),
			},
			{
				id: 4,
				status: false,
				name: "关于我们",
				icon: require("../../assets/images/tabbar/about-white@2x.png"),
				actIcon: require("../../assets/images/tabbar/about-mazarine@2x.png"),
			},
		],
		consultView:{
			title:'咨询',
			name:'姓名',
			contactWay:'联系方式',
			issue:'请输入您的问题',
			submit:'提交',
		}
	},
};
